<template>
  <div>
    <v-row>
      <v-col
        cols="6"
        offset="3"
      >
        <div class="ghd-helveticanew text-black text-center mb-5 text-medium">
          Inserisci il codice di verifica ricevuto tramite email
        </div>
        <v-card
          class="ghd-border-black"
          style="max-width: 480px; margin: auto; flex: 1"
        >
          <v-card-title
            class="ghd-ITCAvant text-center text-uppercase"
            style="color: black !important;"
          >
            Codice di verifica
          </v-card-title>
          <v-form
            ref="emailForm"
            class="pb-6 pt-2 pl-2 pr-2 multi-col-validation"
          >
            <v-row>
              <v-col
                v-if="!emailVerificationAccount"
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="sendData.email"
                  label="Email"
                  outlined
                  dense
                  hide-details="auto"
                  type="text"
                  :rules="[validators.required]"
                  placeholder="Email"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="sendData.code"
                  label="Codice di verifica"
                  outlined
                  dense
                  hide-details="auto"
                  type="number"
                  :rules="[validators.required, validators.alphaDashValidator, validators.integerValidator, validators.lengthValidator(sendData.code, 6)]"
                  placeholder="Codice di verifica"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  block
                  @click="onSubmit"
                >
                  Verifica
                </v-btn>
                <div class="mt-4">
                  Non hai ricevuto il codice di verifica? <span
                    class="ghd-link"
                    @click="resendVerificationCode"
                  >Chiedine uno nuovo</span>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import {
  integerValidator,
  alphaDashValidator,
  required,
  lengthValidator,
} from '@core/utils/validation'
import { cryptDataIntoLocalStorage } from '@core/utils/crypt'
import axios from '@axios'
import { useRouter } from '@core/utils'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'

export default {
  setup() {
    const emailForm = ref(null)

    // const { isDark } = useAppConfig()
    // isDark.value = false
    const blankSendData = {
      code: null,
      email: localStorage.getItem('email_verification_account'),
    }

    const emailVerificationAccount = computed(() => localStorage.getItem('email_verification_account'))

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))

    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const vm = getCurrentInstance().proxy

    const onSubmit = () => {
      if (emailForm.value.validate()) {
        store.dispatch('user/verificateUser', sendData.value)
          .then(response => {
            const { accessToken } = response.data.data.meta
            localStorage.setItem('accessToken', accessToken)
            const { user } = response.data.data

            // Array dei permessi definitvo da salvare nei permessi
            const permissions = []

            const ability = response.data.data.meta.permissions

            ability.forEach(permission => {
              const permissionSplitted = permission.split('_')
              permissions.push({ action: permissionSplitted[0], subject: permissionSplitted[1] })
            })

            permissions.push({ action: 'read', subject: 'Public' })

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(permissions)

            // Set user's ability in localStorage for Access Control
            cryptDataIntoLocalStorage('userAbility', JSON.stringify(permissions))

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            // delete user.ability

            // Set user's data in localStorage for UI/Other purpose
            cryptDataIntoLocalStorage('userData', JSON.stringify(user))

            localStorage.removeItem('email_verification_account')
            router.push({ name: 'dashboard' })
          }).catch(() => {
          })
      }
    }

    const resendVerificationCode = () => {
      const { email } = { email: emailVerificationAccount.value || sendData.value.email }
      if (!email) {
        Vue.$toast('Necessario inserire una mail', {
          timeout: 2000,
        })

        return
      }

      axios.post('api/emails/verification-code', { email }).then().catch()
    }

    return {
      emailForm,
      sendData,
      emailVerificationAccount,

      resetSendData,
      onSubmit,
      resendVerificationCode,

      // Field Validators
      validators: {
        required,
        alphaDashValidator,
        integerValidator,
        lengthValidator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
